<template>
  <div :class="baseClass + '-login-page'">
    <div :class="baseClass+'-linear-border-container'">
      <div :class="baseClass+'-linear-border-content'">
        <div :class="baseClass+'-container-title'">
          <div class="container">Sign In To Your Account</div>
        </div>
        <div :class="baseClass + '-container-content'">
          <div class="title">{{title}}</div>
          <div class="row" v-for="item,index of formNode" :key="index">
            <div class="label">{{item.label}}</div>
            <input class="input" :type="item.type" />
          </div>
          <div class="btn">
            <img src="/static/actions/login_btn.png" @click="$router.push('/index')" />
          </div>
          <div class="forgot">Forgot password?</div>
        </div>
      </div>
    </div>
    <Tips />
  </div>
</template>

<script>
import Tips from "@/components/Tips.vue";
export default {
  components: {  Tips },
  data () {
    return {
      title: "Sign in",
      formNode: [
        { label: "Enter Username or Email Address", type: "text", formKey: "" },
        { label: "Password", type: "password", formKey: "" },
      ]
    }
  }
}
</script>
<style lang="scss">
.#{$baseClass}-login-page {
  padding: 0 40px 100px;
  .#{$baseClass}-linear-border-container {
    position: relative;
    margin: 123px 0 0;
    .#{$baseClass}-linear-border-content {
      background: #2b89ff;
    }
  }
  .#{$baseClass}-container {
    &-content {
      padding-left: 80px;
      padding-top: 110px;
      padding-bottom: 20px;
      .title {
        font-size: 36px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #ffffff;
        margin-bottom: 16px;
      }
      .row {
        .label {
          font-size: 18px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #ffffff;
          margin-bottom: 13px;
        }
        .input {
          width: 100%;
          height: 60px;
          background: #006cf3;
          border-radius: 4px;
          border: 1px solid #ffffff;
          max-width: 800px;
          margin-bottom: 16px;
          color: #fff;
        }
      }
      .btn {
        padding-top: 24px;
        margin-bottom: 20px;
        img {
          width: 146px;
          cursor: pointer;
        }
      }
      .forgot {
        font-size: 24px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #ffffff;
        cursor: pointer;
        text-decoration: underline;
      }
    }
    &-title {
      width: 100%;
      display: flex;
      justify-content: center;
      position: absolute;
      top: -44px;
      .container {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 60px;
        height: 88px;
        background: linear-gradient(180deg, #fed537 0%, #ff6b94 100%);
        box-shadow: 0px 4px 16px 0px rgba(255, 114, 142, 0.3);
        border-radius: 8px;
        font-size: 36px;
        font-family: Arial-BoldMT, Arial;
        font-weight: normal;
        color: #ffffff;
        line-height: 42px;
        text-shadow: 0px 4px 16px rgba(255, 114, 142, 0.3), 0px 4px 0px #db4d01;
      }
    }
  }
}

@media only screen and (max-width: 1300px) {
  .#{$baseClass}-login-page {
    .#{$baseClass}-container {
      &-title {
        top: -30px;
        .container {
          font-size: 24px;
          height: 60px;
        }
      }
      &-content {
        padding-left: 0;
        padding-top: 50px;
        display: flex;
        flex-direction: column;
        align-items: center;
        .title {
          font-size: 24px;
          margin-bottom: 16px;
        }
        .row {
          display: flex;
          flex-direction: column;
          align-items: center;
          .label {
            font-size: 18px;
            margin-bottom: 13px;
          }
          .input {
            height: 34px;
            width: 495px;
          }
        }
        .btn {
          img {
            width: 82px;
          }
        }
        .forgot {
          font-size: 16px;
        }
      }
    }
  }
}
</style>